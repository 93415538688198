export const KIA_BRAND_MODELS_FIELDS = [
  'range_ids.name',
  'range_ids.logo',
  'range_ids.id',
  'range_ids.specials',
  'range_ids.sort',
];
export const ALL_AVAILABLE_SPECIALS = [
  'id',
  'discount_value',
  'title',
  'image',
  'special_for',
  'description',
  'monthly_repayment',
  'special_type',
  'sub_title',
  'special_category.id',
  'special_category.title',
  'special_category.description',
  'special_category.value',
  'brand.name',
  'brand.id',
  'range.name',
  'range.id',
  'dealerships.dealerships_id.id',
  'dealerships.dealerships_id.name',
  'dealerships.dealerships_id.city.id',
  'dealerships.dealerships_id.city.name',
  'dealerships.dealerships_id.city.province.id',
  'dealerships.dealerships_id.city.province.name',
  'short_description',
  'sale_price_incl_vat',
  'sale_percentage',
  'vehicle_type',
  'national_special',
];
export const KIA_BRAND_ID = 52;
export const KIA_BRAND_NAME = 'KIA';
export const NEW_KIA_SPECIALS_PATH = 'new-kia-specials';
export const NEW_KIA_SPECIALS_ID = 44;
export const PRICE_CRUSH_SPECIALS_ID = 1;
export const NEW_KIA_PRICE_CRUSH_SPECIALS_ID = 45;
export const KONFIDENCE_SPECIAL_ID = 114;

export const FILTER_FOR_NEW_KIA_SPECIALS = {
  special_category: {
    _eq: NEW_KIA_SPECIALS_ID,
  },
};

export const ALL_SPECIALS_FIELDS = [
  '*',
  'brand.name',
  'brand.code',
  'special_category.*',
  'model.id',
  'special_for',
  'model.name',
  'range.id',
  'range.name',
];

export const SPECIALS_FIELDS = [
  'image',
  'specials',
  'vehicle.id',
  'special_price',
  'special_for',
  'special_category.title',
  'special_category.id',
];
export const PAGE_SIZE = 12;
export const SPECIALS_CATEGORIES_PATHS_FIELDS = ['value'];
export const SPECIALS_CATEGORIES_FIELDS = [
  'title',
  'description',
  'id',
  'value',
];
export const SPECIALS_CONTENT_FIELDS = [
  'image_text_title',
  'image_text_description',
  'about_kia',
  'new_kia_specials_title',
  'new_kia_specials_by_model_description',
  'new_kia_price_crush_specials_title',
  'new_kia_price_crush_specials_description',
  'konfidence_used_car_price_crush_specials_title',
  'konfidence_used_car_price_crush_specials_description',
  'konfidence_used_kia_specials_title',
  'konfidence_used_kia_specials_description',
  'title',
  'description',
  'image',
  'mobile_image',
  'url',
  'freedom_day_deals_title',
  'freedom_day_deals_description',
  'specials_by_brand_title',
  'specials_by_brand_description',
  'new_kia_specials_by_model_title',
  'new_kia_specials_description',
  'seo_data.*',
  'footer_call_to_action_image',
  'footer_call_to_action.*',
];

export const SPECIALS_ITEMS_COUNT = 12;

export const SPECIALS_ITEMS_COUNT_META_DATA = {
  filterCount: 0,
  currentPage: 1,
};

export const FILTER_FOR_ALL_AVAILABLE_SPECIALS = {
  start_date: { _lte: '$NOW' },
  end_date: { _gte: '$NOW' },
  status: { _eq: 'approved' },
  special_category: {
    id: {
      _nin: [
        NEW_KIA_SPECIALS_ID,
        41,
        NEW_KIA_PRICE_CRUSH_SPECIALS_ID,
        PRICE_CRUSH_SPECIALS_ID,
        KONFIDENCE_SPECIAL_ID,
      ],
    },
  },
};

export const FILTER_FOR_ALL_USED_CAR_SPECIALS = {
  start_date: { _lte: '$NOW' },
  end_date: { _gte: '$NOW' },
  status: { _eq: 'approved' },
  vehicle_type: { _in: [1, 2, 3] },
};

export const FILTER_FOR_ALL_CAR_SPECIALS = {
  start_date: { _lte: '$NOW' },
  end_date: { _gte: '$NOW' },
  status: { _eq: 'approved' },
};

export const FILTER_FOR_ALL_NEW_CAR_SPECIALS = {
  start_date: { _lte: '$NOW' },
  end_date: { _gte: '$NOW' },
  status: { _eq: 'approved' },
  vehicle_type: { _eq: 4 },
  special_category: {
    id: {
      _eq: NEW_KIA_SPECIALS_ID,
    },
  },
};

export const STATUS_SPECIALS = {
  start_date: { _lte: '$NOW' },
  end_date: { _gte: '$NOW' },
  status: { _eq: 'approved' },
};

export const VEHICLE_TYPE_FILTER = {
  demo: [3],
  konfidenceOrUsed: [2, 1],
};

export const VEHICLE_TYPE = [
  { id: '1', name: 'used-cars' },
  { id: '2', name: 'konfidence-cars' },
  { id: '3', name: 'demo-cars' },
  { id: '4', name: 'new-cars' },
];

export const VEHICLE_TYPE_NEW = '4';

export const SPECIAL_FOR_VEHICLE = 'vehicle';

export const SPECIAL_FOR_SERVICE = 'service';

export const SPECIAL_FOR_PARTS = 'parts';

export const SEO_SPECIAL = {
  DEFAULT_TITLE:
    'New, Demo & Used Kia Specials | South Africa | Kia Retail Group',
  CAR_SPECIAL: 'Car Specials',
  BRAND_DESCRIPTION:
    '{0} Specials - find out more from Kia Retail Group in South Africa. Discover more about our latest {0} specials and services available when you choose us at Kia Retail Group.',
  CATEGORY_DESCRIPTION:
    '{0} from the Kia Retail Group in South Africa. Contact Us today for a Test Drive.',
  USED_CARS_DESCRIPTIONS:
    '{0} from the Kia Retail Group in South Africa. Contact Us today for a Test Drive.',
};
