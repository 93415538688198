'use client';
import {
  Box,
  SimpleGrid,
  Stack,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
} from '@chakra-ui/react';
import { useRouter } from 'next/navigation';

import LinkCustom from '@/app/_components/link/LinkCustom';
import { PriceFormat } from '@/app/_components/price-format/price-format';
import { ReactComponent as PromotionTag } from '@/app/_icons/promotion-tag_white.svg';
import { ReactComponent as StarTag } from '@/app/_icons/star.svg';
import { getLeadTypeForSpecial } from '@/services/specials/specials.service';
import { Special } from '@/types/specials/specials.types';

import CustomImage from '../custom-image/CustomImage';

import Styles from './PromotionsCard.module.scss';

const PromotionsCard = ({
  item,
  isButtonVisible = true,
  hrefBase = '/promotions',
}: {
  item: Special;
  isButtonVisible?: boolean;
  hrefBase?: string;
}) => {
  const {
    title = '',
    sub_title = '',
    monthly_repayment = 0,
    cashback = false,
    payment_terms = 0,
    deposit = 0,
    residual = 0,
    interest_rate = 0,
    image = '',
  } = item;

  const router = useRouter();
  const leadType = getLeadTypeForSpecial(item);

  const showPriceInfo = monthly_repayment > 0 || deposit > 0;

  return (
    <Box
      className={Styles.promotionsCard}
      display={'flex'}
      flexDirection={'column'}
      data-testid="promotion-card"
      data-promotion-id={item.id}
    >
      <Box
        className={Styles.promotionsCardImage}
        onClick={() => router.push(`${hrefBase}/${item.id}`)}
      >
        {image && (
          <CustomImage
            loading="lazy"
            imageSrc={image}
            imageKeys={{
              desktopKey: 'image-400-476',
              mobileKey: 'image-400-476',
            }}
            width={320}
            height={476}
          />
        )}
      </Box>
      <Box
        display={'flex'}
        flexDir={'row'}
        alignItems={'center'}
        justifyContent={'end'}
        className={Styles.promotionsCardHeader}
        gap={2}
        padding={6}
      >
        {cashback ? (
          <Tag
            borderRadius={0}
            variant="solid"
            className={`${Styles.promotionTag} ${Styles.darkTag}`}
          >
            <TagLeftIcon as={StarTag} width={4} height={4} />
            <TagLabel>Cash Back</TagLabel>
          </Tag>
        ) : null}
        {payment_terms || deposit || residual || interest_rate ? (
          <Tag
            borderRadius={0}
            variant="solid"
            className={`${Styles.promotionTag} ${Styles.redTag}`}
          >
            <TagLeftIcon as={PromotionTag} width={4} height={4} />
            <TagLabel>Finance</TagLabel>
          </Tag>
        ) : null}
      </Box>
      <Box
        className={Styles.promotionsCardBody}
        marginTop={'auto'}
        paddingX={[2, 2, 6, 6]}
      >
        <Box
          className={Styles.carModelName}
          textTransform={'capitalize'}
          data-testid="promotion-title"
        >
          {title}
        </Box>
      </Box>
      <Box
        className={Styles.promotionsCardFooter}
        paddingX={[2, 2, 6, 6]}
        paddingBottom={6}
      >
        <Stack
          display={'flex'}
          flexDirection={'row'}
          className={Styles.carModelDetail}
          gap={2}
        >
          <Box className={Styles.carInfo} maxW={'180'} width={'100%'} mt={4}>
            <Text
              data-testid="promotion-description"
              className={Styles.description}
            >
              {sub_title}
            </Text>
          </Box>
          {showPriceInfo && (
            <Box className={Styles.carPriceInfo} marginLeft={'auto'}>
              <Text className={Styles.carPriceDesc}>
                {deposit > 0 ? 'From' : 'Zero deposit from'}
              </Text>
              <Text className={Styles.carPrice}>
                <PriceFormat prefix="R" value={monthly_repayment} />
                {monthly_repayment ? 'p/m' : null}
              </Text>
              <Text className={Styles.carPriceDesc} textAlign={'right'}>
                Inc VAT
              </Text>
            </Box>
          )}
        </Stack>
        {isButtonVisible && (
          <SimpleGrid
            columns={[2, 2, 2, 2]}
            alignItems={'start'}
            spacing={[3]}
            mt={4}
            paddingX={{ base: 0, md: 6 }}
            className={Styles.onHoverCTA}
          >
            <LinkCustom
              className="btnOutlineWhite"
              moduleClass={Styles.btnCTA}
              href={`${hrefBase}/${item.id}`}
              data-testid="explore-button"
            >
              Explore
            </LinkCustom>
            <LinkCustom
              className="btnOutlineWhite"
              moduleClass={Styles.btnCTA}
              href={`${hrefBase}/${item.id}?form=${leadType}`}
              data-testid="enquire-button"
            >
              Enquire now
            </LinkCustom>
          </SimpleGrid>
        )}
      </Box>
    </Box>
  );
};

export default PromotionsCard;
